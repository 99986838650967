import { useElementContext } from "@ultracommerce/react-storefront/global";
import { useLayoutEffect } from "react";

const FormCheckboxField = (props) => {
  const {
    CommonModule: { FormCheckboxGroupField },
  } = useElementContext();

  useLayoutEffect(()=>{
    if(props.value === undefined)
      props.setValue((prevState)=>({ ...prevState, [props.attributeCode]: false }));
  },[props])

  return <FormCheckboxGroupField {...props} options={[{ value: !!props.value, name: props.attributeName }]} />;
};

export default FormCheckboxField;
