import { useState } from "react";

const OrderNotes = ({ isFetching, orderProperties, setOrderProperties }) => {
  const [showLimitMessage, setShowLimitMessage] = useState(false);

  const handleChange = (e) => {
    const value = e.target.value;
    setShowLimitMessage(value.length >= e.target.maxLength);
    setOrderProperties({ ...orderProperties, orderNotes: value });
  };

  return (
    <div className="form-group mb-2">
      <textarea
        className="form-control UpdatePropertyTextArea"
        rows="2"
        maxLength="500"
        disabled={isFetching}
        id="order-comments"
        value={orderProperties.orderNotes}
        placeholder="Order notes"
        onChange={handleChange}
      />
      {showLimitMessage && <small className="text-danger">Limit reached: 500 characters max.</small>}
    </div>
  );
};

export { OrderNotes };