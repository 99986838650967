import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PublicInterface } from "@ultracommerce/react-storefront/global/src/interface/common";
import {
  getBrandRoute,
  useElementContext,
  useUtilities,
} from "@ultracommerce/react-storefront/global";
import { useSelector } from "react-redux";
import { ProductPrice } from "../../modules/Product/Components/ProductPrice/ProductPrice.component";
import { IOptionSet } from "./CustomOptions";
import PdpPageModel from "@ultracommerce/react-storefront/global/src/pages/ProductDetailsPage/ProductDetailsPage.pageModel";
import { RelatedProductTypeSlider } from "../../components/Product/RelatedProductsSlider/RelatedProductTypeSlider.component";
import { PageContentRow } from "@ultracommerce/react-storefront/global/src/components";

const checkImageTransparency = (url: string): Promise<boolean> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        resolve(false);
        return;
      }

      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);

      const imageData = ctx.getImageData(0, 0, img.width, img.height);
      const pixels = imageData.data;
      let hasTransparency = false;

      for (let i = 3; i < pixels.length; i += 4) {
        if (pixels[i] < 255) {
          hasTransparency = true;
          break;
        }
      }

      resolve(hasTransparency);
    };

    img.onerror = () => {
      resolve(false);
    };

    img.src = url;
  });
};

const ProductDetailDisplayView = ({
  product,
  productDescription,
  helmet,
  isFetching,
  showLoginForPrice,
  additionalInformation,
  productDetailGallery,
  crumbs,
  dynamicContentElements,
  productBundle,
  favoritesButton,
  skuOptions,
  productForm,
  productAttributes,
  selectedSKu,
  clientCustom: {
    Note,
    extendedProductDescription,
    optionSets,
    settings,
    customOptions,
    setCustomOptions,
    brandName,
    brandUrlTitle,
    isFetchingPrice,
    salePrice,
    listPrice,
    inventoryLocations,
    QATS,
    QOH,
    restrictedFlag,
    skuAllowBackorderFlag,
    BCSManufacturerPartNumber,
  },
}: Omit<PublicInterface<PdpPageModel>, "clientCustom"> & {
  clientCustom: {
    BCSManufacturerPartNumber: any;
    brandName: any;
    brandUrlTitle: any;
    extendedProductDescription: any;
    isFetchingPrice: boolean;
    restrictedFlag: boolean;
    skuAllowBackorderFlag: any;
    listPrice: any;
    settings: any;
    QATS: any;
    QOH: any;
    salePrice: any;
    optionSets?: IOptionSet[];
    Note: any;
    inventoryLocations: any;
    customOptions: { [key: string]: IOptionSet["options"][number] };
    setCustomOptions: Dispatch<
      SetStateAction<{ [key: string]: IOptionSet["options"][number] }>
    >;
  };
}) => {
  const {
    ProductModule,
    CommonModule: { DynamicComponent, Spinner, BreadCrumb },
  } = useElementContext();
  const { t } = useTranslation();
  const brand = useSelector(getBrandRoute);
  const productPrice = { salePrice, listPrice };
  const { eventHandlerForWSIWYG } = useUtilities();
  const [hasTransparency, setHasTransparency] = useState(false);

  useEffect(() => {
    const checkTransparency = async () => {
      if (productDetailGallery?.imageGallery?.[0]?.resizedImagePaths?.[0]) {
        const imageUrl =
          productDetailGallery.imageGallery[0].resizedImagePaths?.[0];
        const transparency = await checkImageTransparency(imageUrl);
        setHasTransparency(transparency as boolean);
      }
    };
    checkTransparency();
  }, [productDetailGallery]);

  const isBackOrderedStockExist =
    QOH > 0 || skuAllowBackorderFlag === "1" ? true : false;
  const totalLocationsInventory =
    inventoryLocations?.reduce((acc: number, inventory: any) => {
      return acc + inventory.QOH;
    }, 0) || 0;

  if (isFetching) {
    return (
      <div className="container mt-5">
        <Spinner />
      </div>
    );
  }

  crumbs = product.breadcrumbs.slice(1).map((crumb, idx) => ({
    title: crumb.productTypeName,
    urlTitle: `/product-type/${product.breadcrumbs
      .slice(1, idx + 2)
      .map((c) => c.urlTitle)
      .join("/")}`,
  }));
  
  const {
    //@ts-ignore
    metaTitleString,
    //@ts-ignore
    metaDescription} = product;

  return (
    <>
      <div className="product-bg-grey">
        <PageContentRow>
          <BreadCrumb crumbs={crumbs} />
        </PageContentRow>
      </div>
      {settings && (
        <Helmet>
          <title>{metaTitleString && metaTitleString !== '' ? metaTitleString : settings?.productTitleString}</title>
          <meta
            name="description"
            content={metaDescription && metaDescription !== '' ? metaDescription : settings?.productMetaDescriptionString}
          />
          <meta name="keywords" content={settings?.productMetaKeywordsString} />
          {helmet?.productSchema && <script type="application/ld+json">{JSON.stringify(helmet.productSchema)}</script>}
        </Helmet>
      )}

      {favoritesButton && ( //changes-favourites display control from config
        <div className="d-flex justify-content-end">
          <ProductModule.CoreComponents.HeartButton
            className="btn-wishlist mr-0 flex-end"
            {...favoritesButton}
          />
        </div>
      )}
      <div className="product-bg-grey">
        <div className="row product-container">
          <div className="col-sm-12 col-lg-4 offset-lg-1 mb-2 gallery-column">
            <div
              style={{
                backgroundColor: hasTransparency ? "white" : "none",
                borderRadius: hasTransparency ? "12px" : "0px",
              }}
            >
              {productDetailGallery && (
                <ProductModule.CoreComponents.ProductDetailGallery
                  {...productDetailGallery}
                />
              )}
            </div>
          </div>

          <div className="col-sm-12 col-lg-6 offset-lg-1">
            {!!brandName?.trim()?.length && (
              <div className="row">
                <div className="col text-decoration-none">
                  <Link className="link" to={`/${brand}/${brandUrlTitle}`}>
                    <h4 className="mb-0">{brandName}</h4>
                  </Link>
                </div>
              </div>
            )}
            <h3 className="fw-bold mb-3">
              {BCSManufacturerPartNumber
                ? BCSManufacturerPartNumber
                : product.productCode}
            </h3>
            <div className="pb-2">
              <strong>
                {extendedProductDescription?.shortdescription?.content}
              </strong>
            </div>
            {product.productCode && (
              <div className="row">
                <div className="col">
                  <strong className="mb-2 text-muted text-uppercase small">
                    {t("frontend.product.sku")}{" "}
                  </strong>
                  {BCSManufacturerPartNumber
                    ? BCSManufacturerPartNumber
                    : product.productCode}
                </div>
              </div>
            )}
            {!!Note && (
              <p className="my-2">
                {Note}
              </p>
            )}
            {isFetchingPrice && (
              <div className="mt-4">
                <span
                  className="spinner-border spinner-border-sm product-loading-spinner"
                  role="status"
                  aria-hidden="true"
                ></span>
              </div>
            )}
            {!isFetchingPrice && productPrice && (
              <div className="my-4 fw-bold">
                <ProductPrice {...productPrice} />
              </div>
            )}
            {/* Configuration Selector */}
            {isBackOrderedStockExist &&
              optionSets?.map((optionSet) => (
                <div className="my-4" key={optionSet.key}>
                  <label className="h6 pb-1">{optionSet.title}</label>
                  <select
                    className="custom-select rounded-pill"
                    value={customOptions[optionSet.key]?.title}
                    onChange={(e) => {
                      const option = optionSet.options.find(
                        ({ title }) => title === e.target.value
                      );
                      setCustomOptions((prevState) => {
                        if (!option) {
                          delete prevState[optionSet.key];
                          return { ...prevState };
                        }
                        return {
                          ...prevState,
                          [optionSet.key]: option,
                        };
                      });
                    }}
                  >
                    <option value="">-- Choose Configuration Setting --</option>
                    {optionSet.options.map((value) => (
                      <option key={value.title} value={value.title}>
                        {value.title}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
            {/* Configuration Selector */}
            {skuOptions && (
              <ProductModule.CoreComponents.SkuOptions {...skuOptions} />
            )}
            {isBackOrderedStockExist && inventoryLocations && (
              <div className="my-4 inventoryData w-50">
                {inventoryLocations.filter(
                  (inventory: any) =>
                    inventory.accountBranchLocationFlag === true
                ).length <= 0 && (
                  <div className="row">
                    <div className="d-flex flex-wrap justify-content-between">
                      {QOH > 0 ? (
                        <span>
                          <div>
                            <b>Available to Ship</b>
                            <span className="availableTag">
                              {totalLocationsInventory}
                            </span>
                          </div>
                        </span>
                      ) : (
                        <div className="availableOrderTag">
                          Available to Order
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {inventoryLocations
                  .filter(
                    (inventory: any) =>
                      inventory.accountBranchLocationFlag === true
                  )
                  .map((inventory: any) => (
                    <>
                      {totalLocationsInventory === 0 ? (
                        <div className="availableOrderTag">
                          Available to Order
                        </div>
                      ) : (
                        <div className="row" style={{ width: "400px" }}>
                          <span className="mb-2">
                            <b>Available to Ship</b>
                            <span className="availableTag">
                              {totalLocationsInventory}
                            </span>
                          </span>
                          <div className="d-flex flex-wrap justify-content-between">
                            <span>
                              <span>
                                <b>
                                  Available for Branch Pickup (
                                  {inventory.locationName})
                                </b>
                                <span className="availableTag">
                                  {inventory?.QOH}
                                </span>
                              </span>
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
              </div>
            )}
            {restrictedFlag && !Note && (
              <div className="alert alert-info" role="alert">
                This product is restricted to authorized contractors only.
                Please contact your nearest branch for details. Visit our branch
                locations page{" "}
                <Link to="/locations" target="_blank">
                  here
                </Link>
                .
              </div>
            )}
            {productForm && !restrictedFlag && isBackOrderedStockExist && (
              <div className="d-flex align-items-center">
                <ProductModule.CoreComponents.ProductForm {...productForm} />
              </div>
            )}
            {showLoginForPrice && ( //changes - config validation and alerts for showing trade price.
              <div className="alert alert-danger pdp-login-alert" role="alert">
                {t("frontend.loginforprice")}
                <br />
                <Link to="/my-account/login">
                  {" "}
                  {t("frontend.account.login")}{" "}
                </Link>
              </div>
            )}
            {productAttributes && (
              <div className="row mb-4">
                <div className="col-md-12">
                  <ProductModule.CoreComponents.ProductAttributes
                    {...productAttributes}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-12 mb-4 mb-md-0 p-5 pt-1">
            {/* Custom related documents */}
            <h3>{extendedProductDescription?.relateddocuments?.title}</h3>
            <ul>
              {extendedProductDescription?.relateddocuments?.content.map(
                (item: any) => (
                  <li>
                    <Link target="_blank" to={item.link}>
                      {item.title}
                    </Link>
                  </li>
                )
              )}
            </ul>
            <h3>Description</h3>
            <div className="pb-2">
              <strong>
                {extendedProductDescription?.shortdescription?.content}
              </strong>
            </div>
            {productDescription && (
              <div
                onClick={eventHandlerForWSIWYG}
                dangerouslySetInnerHTML={{
                  __html: productDescription,
                }}
              />
            )}

            {/* Custom features and befefits */}
            <h3>{extendedProductDescription?.featuresbenefits?.title}</h3>
            <ul>
              {extendedProductDescription?.featuresbenefits?.content?.map(
                (item: any) => (
                  <li>{item}</li>
                )
              )}
            </ul>

            {/* Custom Specifications */}
            <h3>{extendedProductDescription?.specifications?.title}</h3>
            <table className="table table-striped productDetail_table">
              {extendedProductDescription?.specifications?.content.map(
                (item: any) => (
                  <tr className="table-primary">
                    <td>{item.key}</td>
                    <td>{item.value}</td>
                  </tr>
                )
              )}
            </table>

            {additionalInformation && (
              <ProductModule.CoreControl.ProductAdditionalInformation
                {...additionalInformation}
              />
            )}
          </div>
        </div>
      </div>

      {productBundle && (
        <ProductModule.CoreComponents.ProductBundle {...productBundle} />
      )}
      <ProductModule.CoreComponents.RelatedProductsSlider/>
      
      <RelatedProductTypeSlider productID={product.productID} />

      <div className="row">
        <div className="col-sm-12 col-lg-6 offset-lg-1">
          {dynamicContentElements?.map((rowEl: any, rowIdx: number) => (
            <DynamicComponent el={rowEl} key={rowIdx} />
          ))}
        </div>
      </div>
    </>
  );
};
export default ProductDetailDisplayView;
