import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-use";
import { toast } from "react-toastify";
import {
  getErrorMessage,
  isAuthenticated,
} from "@ultracommerce/react-storefront/global";
import { useFormatCurrency } from "@ultracommerce/react-storefront/global";
import { getProductRoute } from "@ultracommerce/react-storefront/global";
import {
  setAllCartOrQuotes,
  setSelectedCartOrQuotes,
  removeItem,
  removeOrderTemplateItem,
} from "@ultracommerce/react-storefront/global";
import {
  jsonParse,
  useElementContext,
} from "@ultracommerce/react-storefront/global";
import { getProductNamePostfix } from "../../pages/ProductDetailsPage/CustomOptions";

const MiniCartAccordionItem = ({
  title,
  className = "",
  isSingleCart,
  isActive,
  children,
  id,
}) => {
  const accordionId = `cart-panel-${id}`;
  const activeRef = useRef(isActive || isSingleCart);
  return (
    <div className={`accordion-item ${className}`}>
      <h2 className="accordion-header pt-0">
        <button
          className={`accordion-button py-1 ${
            activeRef.current ? "" : "collapsed"
          } ${isSingleCart ? "accordion-button--hide-icon" : ""}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${accordionId}`}
          aria-expanded="true"
          aria-controls={accordionId}
          disabled={isSingleCart}
        >
          {title} {isActive && !isSingleCart ? "(Active)" : ""}
        </button>
      </h2>
      <div
        id={accordionId}
        className={`accordion-collapse collapse ${
          activeRef.current ? "show" : ""
        }`}
        aria-labelledby=""
      >
        <div className="accordion-body">{children}</div>
      </div>
    </div>
  );
};

const CartProductItem = ({
  productItems,
  productItem,
  cartAction,
  isBundleItem = false,
}) => {
  const {
    CommonModule: { SimpleImage },
  } = useElementContext();
  const skuConfiguration = jsonParse(productItem.skuConfiguration);
  const productNamePostfix = getProductNamePostfix(skuConfiguration?.options);

  const { t } = useTranslation();
  const [formatCurrency] = useFormatCurrency({});
  const productRoute = useSelector(getProductRoute);
  const { sku, orderItemID, quantity } = productItem;
  const price = cartAction.getPrice(productItem);
  const { product, images } = sku;
  const { productName, urlTitle } = product;
  const childBundleItems = productItems?.filter(
    (filteritem) => filteritem?.parentOrderItemID === orderItemID
  );

  return (
    <div className="d-flex align-items-center py-3 justify-content-between border-bottom border-light flex-wrap">
      {images?.length > 0 && (
        <SimpleImage
          className="img-fluid mw-50px productImage"
          src={images?.at(0)}
          alt={productName}
          type="product"
        />
      )}
      <Link to={`/${productRoute}/${urlTitle}`} className="cart-product-name">
        {productName} {productNamePostfix}
      </Link>
      <span className="text-muted small fw-bolder me-1">
        {quantity} x <span className="text-black">{formatCurrency(price)}</span>
      </span>
      {!isBundleItem && (
        <>
          <figure className="m-0">
            <i
              onClick={() => cartAction.removeItem(productItem)}
              className="bi bi-x-circle"
              role="button"
            ></i>
          </figure>
          {childBundleItems?.length > 0 && (
            <div className="accordion mt-3 w-100">
              <div className="accordion-item">
                <p className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button p-1 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse_${orderItemID}`}
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {t("frontend.cart.bundleProducts.child_items")}
                  </button>
                </p>
                <div
                  id={`collapse_${orderItemID}`}
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    {childBundleItems.map((childBundleItem) => (
                      <CartProductItem
                        key={childBundleItem.orderItemID}
                        productItems={[]}
                        productItem={childBundleItem}
                        cartAction={cartAction}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const CartItem = ({ cartItem, isSingleCart, setMiniCartOpen, itemRemoved }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formatCurrency] = useFormatCurrency({});

  const {
    id,
    accordionItemClass,
    title,
    items,
    totalItems,
    subTotal,
    pathLabel,
    pathname,
    cartAction,
    isActive,
  } = cartItem;
  const productItems = items.filter((item) => item.parentOrderItemID === "");
  return (
    <MiniCartAccordionItem
      title={title}
      className={accordionItemClass}
      id={id}
      isActive={isActive}
      isSingleCart={isSingleCart}
    >
      <div className="d-flex justify-content-between py-2">
        <span className="fw-bold ">
          {totalItems} {t("frontend.home.items")}
        </span>
        <span
          onClick={(e) => {
            e.preventDefault();
            navigate({ pathname });
            setMiniCartOpen((prevState) => !prevState);
          }}
          className="fw-bold link"
        >
          {pathLabel}
        </span>
      </div>
      {items.length > 0 && (
        <>
          <div
            style={{
              maxHeight: "50vh",
              overflowY: "auto",
              paddingRight: "10px",
            }}
          >
            {productItems.map((productItem) => {
              return (
                <CartProductItem
                  key={productItem.orderItemID}
                  productItems={productItems}
                  productItem={productItem}
                  cartAction={cartAction}
                />
              );
            })}
          </div>
          <div className="d-flex justify-content-center border-top border-bottom py-2 mb-4">
            <label className="text-muted">
              {t("frontend.home.subtotal")}:{" "}
            </label>{" "}
            <span className="fw-bold">&nbsp;{formatCurrency(subTotal)}</span>
          </div>
          {productItems.filter((item) => item.quoteOnly).length > 0 && (
            <div className="text-danger m-2">
              Your cart has some quote only items, Please review them before
              checkout.
            </div>
          )}
          {isActive && (
            <Link to="/checkout" className="btn btn-dark d-block ">
              {t("frontend.header.checkout")}
            </Link>
          )}
          {itemRemoved && (
            <div className="alert alert-info m-2 small">
              {t("frontend.cart.removeCartItem")}
            </div>
          )}
        </>
      )}
      {/* {!isActive && cartAction.setActiveCart && (
        <div onClick={() => cartAction.setActiveCart(id, title)} className="btn btn-dark d-block mt-2">
          Set As Active
        </div>
      )} */}
      {!totalItems && (
        <div className="alert alert-secondary m-2 small">
          {t("frontend.cart.empty_cart")}
        </div>
      )}
    </MiniCartAccordionItem>
  );
};

const MiniCart = ({ color = "" }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { ProductModule } = useElementContext();

  const wrapperRef = useRef(null);
  const [miniCartOpen, setMiniCartOpen] = useState(false);
  const [quoteModal, setQuoteModal] = useState(false);

  const cart = useSelector((state) => state.cart);
  const orderTemplateCart = useSelector((state) => state.subscriptionCart);
  const cartAndQuotes = useSelector(
    (state) => state?.allCartQuote?.cartAndQuote
  );
  const selectedCartOrQuote = useSelector((state) => state.cartQuoteReducer);
  const [itemRemoved, setItemRemovedFromCart] = useState(false);
  const { isFetching, orderItems, total } = cart;
  const { orderTemplateItems, orderTemplateID, calculatedSubTotal } =
    orderTemplateCart;

  useEffect(() => {
    dispatch(setAllCartOrQuotes());
  }, [dispatch]);

  useEffect(() => {
    if (cartAndQuotes?.length) {
      dispatch(
        setSelectedCartOrQuotes(
          isAuthenticated() && localStorage.getItem("selectedCartOrQuote")
            ? cartAndQuotes?.filter(
                (item) =>
                  item.orderID ===
                  JSON.parse(localStorage.getItem("selectedCartOrQuote"))
                    ?.orderID
              ).length > 0
              ? JSON.parse(localStorage.getItem("selectedCartOrQuote"))
              : {}
            : {}
        )
      );
    }
  }, [dispatch, cartAndQuotes]);

  //Changes - line 59 and 60 the Cart icon is only displayed when more than 992 px and when resized less than 992px, only the cart text is displayed in the drawer
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setMiniCartOpen(false);
      }
    }
    if (miniCartOpen)
      document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [miniCartOpen]);

  const cartAction = useMemo(
    () => ({
      removeItem: (productItem) => {
        const { orderItemID } = productItem;
        dispatch(removeItem(orderItemID)).then((res) => {
          if (res.isSuccess()) {
            setItemRemovedFromCart(true);
            setTimeout(() => {
              setItemRemovedFromCart(false);
            }, 2000);
          }
        });
      },
      setActiveCart: () => {
        dispatch(setSelectedCartOrQuotes({}));
      },
      getPrice: (productItem) => productItem.extendedUnitPriceAfterDiscount,
    }),
    [dispatch]
  );

  const quoteCartAction = useMemo(
    () => ({
      setActiveCart: (orderID, quoteName) => {
        dispatch(
          setSelectedCartOrQuotes({
            orderID,
            quoteName,
          })
        );
      },
    }),
    [dispatch]
  );

  const orderTemplateCartAction = useMemo(
    () => ({
      removeItem: (productItem) => {
        const { orderTemplateItemID } = productItem;
        dispatch(
          removeOrderTemplateItem(orderTemplateID, orderTemplateItemID)
        ).then((response) => {
          if (
            response.isSuccess() &&
            Object.keys(response.success()?.errors || {}).length
          )
            toast.error(getErrorMessage(response.success().errors));
          if (response.isSuccess())
            toast.success(t("frontend.cart.removeCartItem"));
        });
      },
      getPrice: (productItem) => productItem.calculatedTotal,
    }),
    [t, dispatch, orderTemplateID]
  );

  const cartList = useMemo(() => {
    const list = [];
    list.push({
      id: "default",
      title: t("frontend.checkout.cart"),
      items: orderItems,
      totalItems: orderItems.length || 0,
      subTotal: total,
      pathLabel: t("frontend.cart.viewCart"),
      pathname: "/shopping-cart",
      isFetching: isFetching,
      cartAction: cartAction,
      isActive: !selectedCartOrQuote?.orderID,
    });

    cartAndQuotes
      ?.filter((order) => order.orderTypeCode === "otQuoteOrder")
      .forEach((quote) => {
        list.push({
          id: quote.orderID,
          title: quote.quoteName,
          items: [],
          totalItems: quote.totalItems,
          subTotal: 0,
          pathLabel: "View Quote", //t("frontend.cart.viewCart"),
          pathname: `/my-account/quotes/${quote.orderID}`,
          isFetching: false,
          cartAction: quoteCartAction,
          isActive: selectedCartOrQuote?.orderID === quote.orderID,
        });
      });

    if (orderTemplateItems?.length) {
      list.push({
        id: "subscription-cart",
        accordionItemClass: "subscription-cart",
        title: t("frontend.cart.orderTemplateCart"),
        items: orderTemplateItems,
        totalItems: orderTemplateItems.length,
        subTotal: calculatedSubTotal,
        pathLabel: t("frontend.cart.viewSubscriptionCart"),
        pathname: "/scheduled-delivery-cart",
        isFetching: false,
        cartAction: orderTemplateCartAction,
      });
    }

    return list;
  }, [
    t,
    total,
    calculatedSubTotal,
    cartAndQuotes,
    orderItems,
    orderTemplateItems,
    cartAction,
    quoteCartAction,
    orderTemplateCartAction,
    isFetching,
    selectedCartOrQuote,
  ]);

  return (
    <div
      ref={wrapperRef}
      className="ms-2 nav-item dropdown align-self-lg-center"
    >
      <span
        onClick={() => {
          setMiniCartOpen((prevState) => !prevState);
        }}
        id="navbarDropdown"
        style={{ color }}
        className={`cart position-relative text-center clickable ${
          miniCartOpen && "show"
        } ${location?.pathname === "/shopping-cart" && `active`}`}
      >
        {cart.totalItemQuantity > 0 && (
          <i className="small position-absolute top-1 start-100 translate-middle badge rounded-pill bg-primary cart-number-badge">
            {cart.totalItemQuantity}
          </i>
        )}
        <i className="bi bi-cart3 fs-4"></i>
        <span className="d-none cart-icon-text">
          {t("frontend.header.cart")}
        </span>
      </span>
      <div
        className={`cart-dropdown-container dropdown-menu dropdown-menu-end p-0 border-0 shadow-lg ${
          miniCartOpen && "show"
        }`}
        style={{ minWidth: "350px", right: "-2em" }}
      >
        <div className="accordion cart-dropdown" id="miniCartAccordion">
          {miniCartOpen &&
            cartList.map((cartItem) => (
              <CartItem
                key={cartItem.id}
                isSingleCart={cartList.length === 1}
                setMiniCartOpen={setMiniCartOpen}
                cartItem={cartItem}
                itemRemoved={itemRemoved}
              />
            ))}
          {/* <div className="accordion-item mt-4">
            <div className="accordion-body bg-light">
              <div
                className="fw-bold link text-center"
                onClick={() => setQuoteModal((prevState) => !prevState)}
              >
                Add another quote
              </div>
            </div>
            {quoteModal && (
              <ProductModule.CoreComponents.AddProductToQuoteModal
                setQuoteModal={setQuoteModal}
              />
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export { MiniCart };
