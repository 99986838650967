import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { addPickupLocation } from "@ultracommerce/react-storefront/global";
import { SwSelect } from "@ultracommerce/react-storefront/global/src/components";
import { useEffect, useState } from "react";
const PickupLocationPicker = ({
  fulfillment,
  onChangeLocation,
  pickupLocations,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let custDetails = useSelector((state) => state.userReducer);
  const { pickupLocation } = fulfillment;
  const [changingLocation, setChangingLocation] = useState(false);
  useEffect(() => {
    if (
      !pickupLocation?.locationID &&
      custDetails?.branchLocation?.primaryIDValue
    ) {
      dispatch(
        addPickupLocation({
          value: custDetails?.branchLocation?.primaryIDValue,
        })
      );
    }
    if (
      !pickupLocation?.locationID &&
      !custDetails?.branchLocation?.primaryIDValue
    ) {
      setChangingLocation(true);
    }
  }, []);

  return (
    <>
      <div className="row mb-3">
        <div className="col-sm-12">
          {!changingLocation && pickupLocation?.locationID && (
            <>
              <div className="h4">Pickup Location</div>
              <div className="h5">{pickupLocation?.locationName}</div>
              <div
                onClick={() => setChangingLocation(true)}
                type="button"
                style={{ cursor: "pointer", color: "#007EB1" }}
              >
                Change Pickup Location
              </div>
            </>
          )}
          {changingLocation && pickupLocations?.length > 0 && (
            <>
              <div className="h4">{t("frontend.checkout.location_option")}</div>
              <div style={{ maxWidth: "250px", width: "100%" }}>
                <SwSelect
                  id="pickupLocation"
                  value={pickupLocation?.locationID || ""}
                  options={[
                    ...(!pickupLocation?.locationID
                      ? [{ value: "", name: t("frontend.core.select") }]
                      : []),
                    ...pickupLocations,
                  ]}
                  onChange={(e) => {
                    onChangeLocation(e.target.value);
                    setChangingLocation(false);
                  }}
                />
              </div>
              {pickupLocation?.locationID && (
                <div
                  onClick={() => setChangingLocation(false)}
                  style={{ cursor: "pointer", color: "#007EB1" }}
                >
                  Cancel
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div>
        <h5>{t("frontend.confirmdetails")}</h5>
        <div className="row">
          <div className="me-2 d-flex col-5 flex-wrap">
            <b>{t("frontend.contact.firstName")}: &nbsp;</b>
            <p>{custDetails?.firstName}</p>
          </div>
          <div className="d-flex col-5 flex-wrap">
            <b>{t("frontend.contact.lastName")}: &nbsp;</b>
            <p>{custDetails?.lastName}</p>
          </div>
        </div>
        <div className="row">
          <div className="me-2 d-flex col-5 flex-wrap">
            <b>{t("frontend.account.company")}: &nbsp;</b>
            <p>{custDetails?.company}</p>
          </div>
          <div className="d-flex col-5 flex-wrap">
            <b>{t("frontend.contact.emailAddress")}: &nbsp;</b>
            <p>{custDetails?.primaryEmailAddress.emailAddress}</p>
          </div>
        </div>
        <div className="row">
          <div className="d-flex flex-wrap">
            <b>{t("frontend.contact.phoneNumber")}: &nbsp;</b>
            <p>{custDetails?.primaryPhoneNumber.phoneNumber}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export { PickupLocationPicker };
